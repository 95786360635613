/* import __COLOCATED_TEMPLATE__ from './insights-report-v3-monitor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { filterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import type RouterService from '@ember/routing/router-service';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import {
  and,
  or,
  not,
  ConversationFunnelConfig,
} from 'embercom/components/reporting/automation/ai-insights/funnel-config';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import type CustomReport from 'embercom/models/reporting/custom/report';
import type CustomChart from 'embercom/models/reporting/custom/chart';
import type { Topic } from 'embercom/components/reporting/automation/ai-insights/fin-insights/topics-data-resources';

interface Args {
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
}

const queryTypeInformational = ConversationFunnelConfig.filters.queryTypeInformational;
const queryTypeActions = ConversationFunnelConfig.filters.queryTypeActions;
const queryTypeComplex = ConversationFunnelConfig.filters.queryTypeComplex;
const finRoutedToTeam = ConversationFunnelConfig.filters.finRoutedToTeam;
const finConfirmedResolved = ConversationFunnelConfig.filters.finConfirmedResolved;
const finAssumedResolved = ConversationFunnelConfig.filters.finAssumedResolved;
const negativeCSAT = ConversationFunnelConfig.filters.negativeCSAT;
const positiveCSAT = ConversationFunnelConfig.filters.positiveCSAT;
const teammateWasEverInvolved = ConversationFunnelConfig.filters.teammateWasEverInvolved;

const FILTER_STATE_TO_FILTERS = {
  automateWithFin: and(
    queryTypeInformational,
    teammateWasEverInvolved,
    not(finConfirmedResolved),
    not(finAssumedResolved),
  ),
  improveCustomerExperience: and(or(finAssumedResolved, finRoutedToTeam), negativeCSAT),
  improveFirstContactResolution: and(or(queryTypeComplex, queryTypeActions), finRoutedToTeam),
  seeWhereFinIsDoingWell: and(or(finConfirmedResolved, finAssumedResolved), positiveCSAT),
  exploreYourData: and([]),
};

type FilterState = keyof typeof FILTER_STATE_TO_FILTERS;
const DEFAULT_FILTER_STATE = 'exploreYourData';

export default class InsightsReportV3Monitor extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare router: RouterService;
  @tracked reportFilters = {
    type: 'and',
    filters: [],
  };
  @tracked filterState: FilterState = DEFAULT_FILTER_STATE;
  @tracked selectedTopic: string | null = null;
  @tracked subtopicsOfSelectedTopic: string[] = [];
  @tracked showSidePanel = false;
  @tracked selectedTopicObject: Topic | null = null;
  @tracked selectedSubTopic: string | null = null;
  @tracked type: 'suggestions' | 'conversations' = 'suggestions';

  @action
  onClickFilterState(newState: FilterState, reportModel: CustomReport) {
    this.filterState = this.filterState === newState ? DEFAULT_FILTER_STATE : newState;
    this.selectedTopic = null;
    this.subtopicsOfSelectedTopic = [];
    this.applyFiltersToTopicsTreemap(reportModel);
    this.applyFiltersToTopicsTable(reportModel);
  }

  get filtersFromSelectedPreset() {
    return FILTER_STATE_TO_FILTERS[this.filterState];
  }

  get isStandaloneApp() {
    return this.appService.app.isStandaloneApp;
  }

  get filtersFromSelectedTopic() {
    if (this.selectedTopic) {
      return {
        type: 'and',
        filters: [
          {
            type: 'category',
            data: {
              attribute: 'conversation_custom_fields#ai_insights_topic',
              property: 'conversation_custom_fields#ai_insights_topic',
              values: [this.selectedTopic],
            },
          },
          {
            type: 'category',
            data: {
              attribute: 'conversation_custom_fields#ai_insights_subtopic',
              property: 'conversation_custom_fields#ai_insights_subtopic',
              values: this.subtopicsOfSelectedTopic,
            },
          },
        ],
      };
    }
    return { type: 'and', filters: [] };
  }

  // TODO translate all strings once they've been finalized
  /* eslint-disable @intercom/intercom/no-bare-strings */
  get reportDefinition() {
    return {
      id: 'insights_report_v3_monitor',
      title: 'AI Insights Improve',
      description: null,
      charts: [],
      date_range: {
        selection: 'past_4_weeks',
        start: null,
        end: null,
      },
      filters: this.reportFilters,
    };
  }

  get sankeyChartDefinition() {
    return {
      id: 'insights_report_v3.monitor.funnel',
      show_default_chart_title: false,
      title: '',
      description: '',
      chart_series: ConversationFunnelConfig.getChartSeriesWithLabels(this.intl),
      visualization_type: VISUALIZATION_TYPES.BESPOKE,
      component_name: 'ai-insights/sankey-chart',
      visualization_options: {
        show_time_comparison: false,
      },
      metric_property: null,
      metric_function: null,
      stacked: false,
      view_by: null,
      view_by_time_interval: null,
      view_by_display_limit: 10,
      view_by_display_other: false,
      grid_position_x: 0,
      grid_position_y: 0,
      grid_width: 12,
      grid_height: 6,
    };
  }

  get topicsTreemapChartDefinition() {
    return {
      id: 'insights.treemap',
      title: null,
      show_default_chart_title: false,
      visualization_type: 'bespoke',
      component_name: 'ai_insights/monitor/treemap',
      created_by_id: -1,
      updated_by_id: -1,
      stacked: false,
      view_by: 'ai_topic_subtopic_pair',
      view_by_display_limit: 5000,
      view_by_display_other: false,
      segment_by_display_limit: 10,
      segment_by_display_other: false,
      chart_series: [
        {
          metric_id: 'v1.new_conversations',
          aggregation: 'count',
          show_data_labels: true,
          filters: {
            type: 'and',
            filters: [],
          },
        },
        {
          metric_id: 'conversation.ai_generated_metrics.csat_score',
          filters: {
            type: 'and',
            filters: [],
          },
        },
      ],
      visualization_options: {
        show_time_comparison: false,
      },
      grid_position_x: 0,
      grid_position_y: 0,
      grid_width: 12,
      grid_height: 9,
    };
  }

  get topicsTableChartDefinition() {
    return {
      id: 'insights.topics-table',
      title: null,
      show_default_chart_title: false,
      visualization_type: 'tabular',
      stacked: false,
      view_by: 'conversation_custom_fields#ai_insights_topic',
      view_by_display_limit: 100,
      view_by_display_other: false,
      segment_by_display_limit: 7,
      segment_by_display_other: false,
      chart_series: [
        {
          metric_id: 'v1.new_conversations',
          aggregation: 'count',
          label: 'Volume',
        },
        {
          metric_id: 'fin.resolution_rate',
          label: 'Resolution rate',
        },
        {
          metric_id: 'conversation.ai_generated_metrics.csat_score',
          label: 'AI CSAT',
        },
        {
          metric_id: 'fin.conversations_routed_to_team.count',
          label: 'Escalations',
        },
      ],
      visualization_options: {
        allow_drill_in_from_chart: true,
        show_relative_values: false,
        show_summary_row: false,
        show_time_comparison: false,
      },
      grid_position_x: 0,
      grid_position_y: 11,
      grid_width: 12,
      grid_height: 8,
    };
  }

  get filterableProperties() {
    return [...filterableProperties(), 'teammate', 'team', 'conversation.replied.workflow_ids'];
  }

  @action
  onSelectedTopicChanged(reportModel: CustomReport, topic: string | null, subtopics: string[]) {
    this.selectedTopic = topic;
    this.subtopicsOfSelectedTopic = subtopics;
    let topicsTable = reportModel.charts.lastObject;
    this.applyChangesToTopicsTable(topicsTable);
    this.applyFiltersToTopicsTable(reportModel);
  }

  @action
  onShowConversationExplorer(
    topic: Topic,
    subtopic: string | null,
    type: 'suggestions' | 'conversations',
  ) {
    this.selectedTopicObject = topic;
    this.selectedSubTopic = subtopic;
    this.type = type;
    this.showSidePanel = true;
  }

  @action
  onCloseConversationExplorer() {
    this.showSidePanel = false;
  }

  // TODO translate all strings once they've been finalized
  /* eslint-disable @intercom/intercom/no-bare-strings */
  applyChangesToTopicsTable(topicsTable: CustomChart) {
    if (this.selectedTopic) {
      topicsTable.title = 'Subtopics';
      topicsTable.viewBy = 'conversation_custom_fields#ai_insights_subtopic';
    } else {
      topicsTable.title = 'Topics';
      topicsTable.viewBy = 'conversation_custom_fields#ai_insights_topic';
    }
  }

  applyFiltersToTopicsTreemap(reportModel: CustomReport) {
    let allCharts = reportModel.charts.toArray();
    let [_sankey, topicsTreemap, _topicsTable] = allCharts;

    this.applyFiltersToChart(topicsTreemap, this.filtersFromSelectedPreset);
  }

  applyFiltersToTopicsTable(reportModel: CustomReport) {
    let allCharts = reportModel.charts.toArray();
    let [_sankey, _topicsTreemap, topicsTable] = allCharts;

    this.applyFiltersToChart(
      topicsTable,
      and(this.filtersFromSelectedPreset, this.filtersFromSelectedTopic),
    );
  }

  applyFiltersToChart(chart: CustomChart, filters: any) {
    chart.chartSeries.forEach((series: ChartSeries) => {
      series.filters = filters;
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::InsightsReportV3Monitor': typeof InsightsReportV3Monitor;
  }
}
