/* import __COLOCATED_TEMPLATE__ from './conversation-explore-panel.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { type TaskGenerator, type TaskInstance } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import {
  type LogicalFilter,
  type TopLevelFilter,
} from 'embercom/components/reporting/custom/filters';
import { type FlexibleQueryTimeRange } from 'embercom/services/reporting-csv-export';
import { tracked } from '@glimmer/tracking';
import type { Topic } from 'embercom/components/reporting/automation/ai-insights/fin-insights/topics-data-resources';
import type { SerializedChart } from 'embercom/components/reporting/in-context/static-report';
import Range from 'embercom/models/reporting/range';
import type IntlService from 'ember-intl/services/intl';
import type ReportingChartService from 'embercom/services/reporting-chart-service';
import { localCopy } from 'tracked-toolbox';
import { isPresent } from '@ember/utils';

interface Args {
  showSidePanel: boolean;
  onClose: () => void;
  selectedTopic: Topic | null;
  selectedSubTopic: string | null;
  reportDefinition?: {
    id: string;
    charts: SerializedChart[];
    filters: TopLevelFilter;
    date_range?: any;
  };
  dateRange?: Range;
  isStandalone: boolean;
  activeTab?: Tab;
  filtersFromSelectedPreset?: any;
  metricsForRow?: Record<string, string>;
}

export interface ConversationExploreConversation {
  id: number;
  userName: string;
  userPseudonym: string;
  topicSummary: string;
  subtopic: string;
  topic: string;
}

const CONVERSATION_PAGE_SIZE = 100;

type Tab = 'suggestions' | 'conversations';

export default class ConversationExplorePanel extends Component<Args> {
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare appService: any;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare intl: IntlService;
  @service declare reportingChartService: ReportingChartService;
  @tracked selectedConversationId = 0;
  @tracked conversationIds: number[] = [];
  @tracked totalHits = 0;
  @tracked pageFrom = 0;
  @tracked loadedConversations: ConversationExploreConversation[] = [];
  @localCopy('args.activeTab') selectedTab: Tab = 'conversations';
  fetchDataTask = taskFor(this.loadTopicDrillInData);

  conversationData = trackedTask(this, this.fetchDataTask, () => [
    this.args.selectedTopic,
    this.pageFrom,
  ]) as TaskInstance<ConversationExploreConversation[]>;

  get filterState(): LogicalFilter {
    let filter = [
      {
        type: 'category',
        data: {
          attribute: 'conversation_custom_fields#ai_insights_topic',
          property: 'conversation_custom_fields#ai_insights_topic',
          values: [this.args.selectedTopic?.name],
        },
      },
    ];
    if (this.args.selectedSubTopic) {
      filter.push({
        type: 'category',
        data: {
          attribute: 'conversation_custom_fields#ai_insights_subtopic',
          property: 'conversation_custom_fields#ai_insights_subtopic',
          values: [this.args.selectedSubTopic],
        },
      });
    }
    if (isPresent(this.args.filtersFromSelectedPreset)) {
      filter.push(...this.args.filtersFromSelectedPreset.filters);
    }
    return {
      type: 'and',
      filters: filter,
    };
  }

  @restartableTask
  *loadTopicDrillInData(): TaskGenerator<ConversationExploreConversation[]> {
    if (this.pageFrom === 0) {
      this.loadedConversations = [];
    }
    if (this.args.selectedTopic) {
      let filters: LogicalFilter = this.filterState;

      let metricId = 'v1.new_conversations';
      let metric = this.reportingMetrics.getMetricById(metricId);
      let timezone = this.appService.app.timezone;
      let dateRange =
        this.args.dateRange || Range.createFromPreset('past_4_weeks', this.appService.app.timezone);
      let timeRange: FlexibleQueryTimeRange = {
        start: dateRange.startMoment.valueOf(),
        end: dateRange.endMoment.valueOf(),
        interval: dateRange.interval,
        property: metric.timeProperty,
      };
      let attributeIds = [
        'conversation.id',
        'conversation.first_user.name',
        'conversation.first_user.pseudonym',
        'conversation_custom_fields#ai_insights_conversation_summary',
        'conversation_custom_fields#ai_insights_subtopic',
        'conversation_custom_fields#ai_insights_topic',
      ];
      let sortBy = 'conversation.first_user_conversation_part_created_at';
      let sortDirection: 'asc' | 'desc' = 'desc';
      let useSyntheticData = this.reportingChartService.useSyntheticData;
      let isBeingRenderedAsAChart = false;
      let pageFrom = this.pageFrom;
      let pageSize = CONVERSATION_PAGE_SIZE;
      let result = yield this.reportingUnderlyingDataService.loadDrillInResult(
        this.appService.app.id,
        metric.datasetId,
        filters,
        pageFrom,
        pageSize,
        timeRange,
        attributeIds,
        sortBy,
        sortDirection,
        useSyntheticData,
        timezone,
        isBeingRenderedAsAChart,
      );
      if (result.data) {
        this.onConversationSelected(result.data[0]['conversation.id']);
      }
      this.totalHits = result.totalHits;
      this.loadedConversations.pushObjects(
        result.data.map(
          (conversation: any) =>
            ({
              id: conversation['conversation.id'],
              userName: conversation['conversation.first_user.name'],
              userPseudonym: conversation['conversation.first_user.pseudonym'],
              topicSummary:
                conversation['conversation_custom_fields#ai_insights_conversation_summary'],
              subtopic: conversation['conversation_custom_fields#ai_insights_subtopic'],
              topic: conversation['conversation_custom_fields#ai_insights_topic'],
            }) as ConversationExploreConversation,
        ),
      );
      this.conversationIds = this.loadedConversations.map((conversation) => conversation.id);
    }
    return [];
  }

  get hasMoreConversations() {
    return this.loadedConversations.length < this.totalHits;
  }

  @action
  loadMoreConversations() {
    this.pageFrom += CONVERSATION_PAGE_SIZE;
  }

  @action
  onConversationSelected(conversationId: number) {
    this.selectedConversationId = conversationId;
  }

  @action
  onClose() {
    this.args.onClose();
    this.pageFrom = 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::ConversationExplorePanel': typeof ConversationExplorePanel;
    'reporting/automation/ai-insights/conversation-explore-panel': typeof ConversationExplorePanel;
  }
}
