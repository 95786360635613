/* import __COLOCATED_TEMPLATE__ from './suggestion-button.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SuggestionService from 'embercom/services/suggestion-service';
import type IntlService from 'ember-intl/services/intl';
import { type ReviewTypeCount } from 'embercom/services/suggestion-service';
import { ReviewType } from 'embercom/models/content-service/content-review-request';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  topicId: string;
  subTopicId: string | null;
  onClick: () => void;
}
export default class SuggestionButton extends Component<Args> {
  @service declare suggestionService: SuggestionService;
  @service declare intl: IntlService;

  get topicsSuggestionCount() {
    if (this.args.subTopicId) {
      // If subTopicId is set, then we need to get the count for the subtopics grouped by the topicId
      return (
        this.suggestionService.topicsSummary
          .get(this.args.topicId)
          ?.subtopics.find((subtopic) => subtopic.name === this.args.subTopicId)?.count ?? 0
      );
    }
    return this.suggestionService.topicsSummary.get(this.args.topicId)?.totalCount ?? 0;
  }

  get maxCountReviewType(): ReviewTypeCount {
    if (this.args.topicId && this.args.subTopicId) {
      let subtopic = this.suggestionService.topicsSummary
        .get(this.args.topicId)
        ?.subtopics.find((subtopic) => subtopic.name === this.args.subTopicId);

      if (subtopic && subtopic.reviewTypeCounts.length > 0) {
        return this.getReviewTypeByMaxCount(subtopic.reviewTypeCounts);
      }
    }

    let topic = this.suggestionService.topicsSummary.get(this.args.topicId);
    if (topic && topic.reviewTypeCounts.length > 0) {
      return this.getReviewTypeByMaxCount(topic.reviewTypeCounts);
    }
    return { review_type: ReviewType.CREATE_CONTENT, count: 0 };
  }

  private getReviewTypeByMaxCount(reviewTypeCounts: ReviewTypeCount[]) {
    return reviewTypeCounts.reduce(
      (maxType, currentType) => (currentType.count > maxType.count ? currentType : maxType),
      reviewTypeCounts[0],
    );
  }

  get icon(): InterfaceIconName | undefined {
    if (this.topicsSuggestionCount === 0) {
      return undefined;
    }
    return this.maxCountReviewType.review_type === ReviewType.CREATE_CONTENT
      ? 'knowledge'
      : 'ai-editing';
  }

  get label() {
    if (this.topicsSuggestionCount === 0) {
      return this.intl.t('automation.ai-insights.suggestions.suggestion-button-label-none');
    } else {
      let labelType =
        this.maxCountReviewType.review_type === ReviewType.CREATE_CONTENT
          ? 'add-content'
          : 'edit-content';

      return this.intl.t(
        `automation.ai-insights.suggestions.suggestion-button-label-${labelType}`,
        {
          count: this.topicsSuggestionCount - 1,
        },
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::FinInsights::SuggestionButton': typeof SuggestionButton;
  }
}
